import Swal from 'sweetalert2'
import { type SweetAlertResult } from 'sweetalert2'

type SwalPlugin = {
    fire: typeof Swal.fire;
    success: (title: string) => Promise<SweetAlertResult<any>>;
    error: (title: string) => Promise<SweetAlertResult<any>>;
    warning: (title: string) => Promise<SweetAlertResult<any>>;
    info: (title: string) => Promise<SweetAlertResult<any>>;
    loading: (title: string) => typeof Swal;
}

// Создаем инстанс с настройками по умолчанию
const SwalInstance = Swal.mixin({
    customClass: {
        confirmButton: 'bg-primary-500 hover:bg-primary-600 text-white px-4 py-2 rounded',
        cancelButton: 'bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded ml-2',
    },
    buttonsStyling: false // Отключаем встроенные стили кнопок
})

export default defineNuxtPlugin((nuxtApp) => {
    const swal: SwalPlugin = {
        fire: (...args) => SwalInstance.fire(...args),
        success: (title) => SwalInstance.fire({ icon: 'success', title }),
        error: (title) => SwalInstance.fire({ icon: 'error', title }),
        warning: (title) => SwalInstance.fire({ icon: 'warning', title }),
        info: (title) => SwalInstance.fire({ icon: 'info', title }),
        loading: (title, text = null) => {
            SwalInstance.fire({
                title: title,
                text: text,
                icon: 'info',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    SwalInstance.showLoading()
                }
            })
            return SwalInstance
        }
    }

    return {
        provide: {
            swal
        }
    }
})